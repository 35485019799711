/**
 * @flow
 */

import React, {Component, useMemo} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, change, formValueSelector} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';

import styled from 'styled-components';
import theme from '../../../theme/index';
import Wrapper from '../../../components/Typografy/Wrapper';
import BaseButton from '../../../components/Typografy/BaseButton';
import Select from '../../../components/Select'

import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import CustomFieldTextarea from '../../../components/Typografy/CustomFieldTextarea';
import MessageEditFooter from './components/MessageFooter';

import {FaImage as ImageIcon, FaTimesCircle} from 'react-icons/fa';

import type {
    TMessageApiData,
    TMessageResponse,
} from '../../../../common/types/api/TMessage';

import styles from './MessageForm.css'
import {
    buildAccountLogo,
    buildBenefitImage,
    buildMessageImage, readAccountLogo,
    readMessageImage
} from "../../../../common/config/firebase_config/endpoints";
import {imageMedium} from "../../../../common/config/images/endpoints";
import ImageUploaderModal from "../../../components/form/ImageUploaderModal";
import {ImageButton} from "../../../SuperAdmin/Accounts/AccountEdit/components/CustomCategories";
import ImageUploader from "../../../components/form/ImageUploader";

import {v4 as uuidv4} from 'uuid';
import ToggleSwitch from "../../../components/ToggleSwitch";
import {Dropdown, MultiSelect} from "react-multi-select-component";
import SelectInput from "../../../components/SelectInput";

const SaveButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }
`;

const Container = styled.div`
  background: ${theme.userProfile.userCardsBackground};
  padding-top: 3.5em;
  padding-bottom: 7em;
`;

const Card = styled(Flex)`
  margin-bottom: 2em;
  padding: 1.7em 1.8em 3em 1.8em;
  box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.3);
  background: ${theme.userProfile.cardBackground};
  border-radius: 20px;
`;

const CardTitle = styled.h2`
  font-size: 1.25em;
  padding-bottom: 1em;
  //text-transform: uppercase;
  border-bottom: 1px solid ${theme.userProfile.cardBorder};
`;

type
TProps = {
    accountId: string;
    initialValues: TMessageApiData;
    hasMessage: boolean;
}

type
TState = {}


const MIN_WIDTH = 500;
const MIN_HEIGHT = 500;

const targetOptions = (targets) => targets.map(step => ({
    label: step.email,
    value: step.token
}));


const renderTargets = ({fields, tokens, meta: {touched, error}}) => {
    return (
        <div>
            {fields.map((step, index) =>
                <div key={index}>
                    <Flex
                        justify="space-between">
                        <Box w={[1, 10 / 12]} mx={10}>
                            <Field
                                name={`${step}.token`}
                                component={Select}
                                label="Target"
                                options={targetOptions(tokens)}/>
                        </Box>
                        <Box w={[1, 2 / 12]} mx={2}>
                            <FaTimesCircle size="50" color="#ececec" onClick={() => fields.remove(index)}
                                           className={styles.faTimesCircle}/>
                        </Box>
                    </Flex>
                </div>
            )}
        </div>
    );/*             <SaveButton name="addStep" id="addStep" type="button" onClick={(event) => {
                if (event) event.preventDefault();
                fields.push({token: ''});
            }}>{(fields.length > 0) ? 'Add target':'Limit delivery'}</SaveButton>
 */
}


class MessageForm extends Component<void, TProps, TState> {
	constructor(props, context) {
		super(props, context);

		this.state = {
			selected: null,
            benefitLink: null,
		}
	}

	render() {
        const messageId = this.props.message ? this.props.message.messageId : ''
        const account = this.props.accounts.find(acc => acc.accountId === this.props.accountId);
        const hasVendors = account?.messageVendors ?? null
		const options = hasVendors ? this.props.accounts.filter(acc => account.messageVendors.some(o => o.accountId === acc.accountId)).map(acc => ({
			value: acc.accountId,
			label: acc.accountName,
			logoUrl: acc.logoUrl,
		})) : [];

        const benefitOptions = Object.values(this.props.benefits?.all)?.map(benefit => ({
            label: benefit.heading,
            value: benefit.benefitId,
        }));

        console.log('options', benefitOptions)
        const isEditing = this.props.message ?? false;
		const showNotif = this.props.messageFormValues?.sendNotification;

        return (
            <div>
                <Container>
                    <Wrapper>
                        <Flex wrap>
                            <form autoComplete="off">
                                <Box style={{display: "flex", flexDirection: "row", gap: 40}}>
                                    <Card w={[0.5]} wrap>
                                        <Box w={[1]}>
                                            <CardTitle>
                                                {isEditing ? "EDIT MESSAGE" : "NEW MESSAGE"}
                                            </CardTitle>

                                            {(hasVendors && !isEditing && options.length > 0) ?
                                                <Box style={{marginBottom: 20, marginTop: 10}}>
                                                    <h4>Forward message to accounts</h4>

                                                    <MultiSelect
                                                        options={options}
                                                        value={this.state.selected ?? options}
                                                        onChange={(change) => this.setState({selected: change})}
                                                        labelledBy="Select accounts"
                                                        overrideStrings={{
                                                            selectSomeItems: "Select accounts...",
                                                            allItemsAreSelected: "All accounts are selected.",
                                                        }}
                                                        ItemRenderer={({checked, option, onClick, disabled}) =>
                                                            <div style={{alignItems: "center"}} className={`item-renderer ${disabled ? "disabled" : ""}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={onClick}
                                                                    checked={checked}
                                                                    tabIndex={-1}
                                                                    disabled={disabled}
                                                                />
                                                                {
                                                                    option.logoUrl ?
                                                                        <img
                                                                            src={option.logoUrl}
                                                                            alt={"account-logo"}
                                                                            style={{
                                                                                width: 50,
                                                                                height: 40,
                                                                                marginRight: 10,
                                                                                marginLeft: 10,
                                                                                objectFit: "contain",
                                                                            }}
                                                                        />
                                                                    :
                                                                        null
                                                                }
                                                                <span>{option.label}</span>
                                                            </div>
                                                        }
                                                        hasSelectAll
                                                    />
                                                </Box>
                                                : null
                                            }
                                        </Box>
                                        <Box>
	                                        <div>
		                                        <Field
			                                        name="sendNotification"
			                                        component={ToggleSwitch}
			                                        label="Send push notifications?"
			                                        defaultValue={true}
			                                        type="checkbox"
		                                        />
	                                        </div>

                                            {(!showNotif) ? null : <div>
	                                            <h4>
		                                            NOTE: This will send a push notification to everyone who has the
		                                            app for this account when you publish the message. Adding to draft will not send a notification.
	                                            </h4>

                                                <Field
                                                    name="text"
                                                    component={CustomFieldInput}
                                                    type="text"
                                                    placeholder="Notification text"
                                                    userCard
                                                    label="* Text that appears on push notifications for this message"
                                                />
                                            </div>}
                                            <div>
                                                <Field
                                                    name="headline"
                                                    component={CustomFieldInput}
                                                    type="text"
                                                    placeholder="App headline"
                                                    userCard
                                                    label="* The title for the message in-app"
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    name="description"
                                                    component={CustomFieldTextarea}
                                                    type="text"
                                                    placeholder="Message text"
                                                    userCard
                                                    rows={2}
                                                    label="* Body of the message in-app"
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    name="url"
                                                    component={CustomFieldInput}
                                                    type="text"
                                                    placeholder="https://coveredpro.com"
                                                    userCard
                                                    label="Add a clickable link to the message"
                                                />
                                            </div>
                                            <Field
                                                name="benefitLink"
                                                options={benefitOptions}
                                                component={SelectInput}
                                                label="Choose a benefit to link to this message"
                                            />
                                            {/*<div>
												<Field
													name="published"
													component={ToggleSwitch}
													label={"Publish"}
													type="checkbox"
												/>
											</div>*/}
                                            <div>
                                                <FieldArray
                                                    name="targets"
                                                    tokens={this.props.tokens}
                                                    component={renderTargets}
                                                />
                                            </div>

                                            <h4>Fields marked * are required</h4>
                                        </Box>
                                    </Card>

                                    <div style={{width: "100%"}}>
                                        <Field
                                            uploadUrl={buildMessageImage(this.props.accountId, uuidv4() + ".png")}
                                            showUrl={null}
                                            minWidth={500}
                                            minHeight={500}
                                            optional
                                            component={ImageUploaderModal}
                                            aspectRatio={16/9}
                                            name="imageUrl"
                                        />
                                    </div>
                                </Box>
                            </form>
                        </Flex>
                    </Wrapper>
                </Container>
                <MessageEditFooter
                    messageId={messageId}
                    pristine={this.props.pristine}
                    isDraft={this.props.isDraft}
                    isArchive={this.props.isArchive}
                    onSave={() => {
                        this.props.updateMessage(
                            this.props.messageFormValues,
                            this.props.message,
                            (this.state.selected ?? options).map(s => s.value),
                        );
                    }}
                    onSaveDraft={() => {
                        this.props.updateMessageDraft(
                            this.props.messageFormValues,
                            this.props.message,
                        );
                    }}
                />
            </div>
        )
            ;
    }
}

export default connect(
    null, {change})
(reduxForm({
    form: 'MessageForm',
    field: ['text', 'sendNotification', 'headline', 'description', 'imageUrl', 'url', 'published', 'benefitLink'],
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
	initialValues: {
		sendNotification: true,
	}
})(MessageForm));
