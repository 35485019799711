/**
 * @flow
 */

import React from 'react';
import {
	Flex,
	Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme/index';
import type { TMessageApiData } from '../../../../common/types/api/TMessage';
import BaseButton from "../../../components/Typografy/BaseButton";
import { confirmAlert } from "react-confirm-alert";
import { Image } from "react-konva";

const MessagesCard = styled.div`
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
  transition: all .2s;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
    transform: scale(.99);
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;


const MessageInfoContainer = styled.div`
	padding: 1.5em;
`;

const MessageTitle = styled.h2`
  font-size: 1.4em;
  color: ${theme.categories.categoryColor};
  margin: 0 0 15px;
  padding: 0;
`;

const MessageSubTitle = styled.p`
  font-size: 1.2em;
  color: ${theme.categories.categoryColor};
  margin: 8px 0;
  padding: 0;
  font-weight: 600;
`;

const MessageText = styled.p`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  opacity: 0.8;
  margin: 8px 0;
  padding: 0;
  font-weight: 600;
`;
const DateText = styled.div`
  font-size: 1.2em;
  color: #dcdcdc;
  display: flex;
  justify-content: flex-start;
`;
const DeleteButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

`;
const DateTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const LastLineContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DeleteButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  
  transition: all .2s;

  &:active {
    outline: none;
    opacity: 0.6;
    scale: 0.9;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.7;
    scale: 0.95;
  }
`;

type
TProps = {
	message: TMessageApiData;
	accountId: number;
	publishMessageDraft: any,
};

const MessageItem = (props: TProps) => {
	const { message, accountId, archiveMessage, publishMessageDraft, restoreArchivedMessage, isDraft, isArchive } = props;

	const hasOrigin = message?.originAccountId && (message.originAccountId !== accountId);

	return (
		<MessagesCard
			style={{
				opacity: hasOrigin ? 0.6 : 1,
				pointerEvents: hasOrigin ? 'none' : 'auto',
			}}
			onClick={(e) => {
				e.preventDefault();

				props.history.push(`/admin/messages${isArchive ? "/archive" : isDraft ? "/drafts" : ""}/${message?.messageId}`);
			}}
		>
			<Flex wrap>
				<Box
					w={[1]}
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					{message.imageUrl ?
						<img
							alt={"preview"}
							src={message.imageUrl}
							style={{
								width: 300,
								height: "100%",
								objectFit: "cover",
							}}
						/> :
						null
					}

					<MessageInfoContainer>
						<MessageText>Headline</MessageText>
						<MessageTitle>{message.headline} </MessageTitle>

						{message?.sendNotification ?
							<div>
								<MessageText>Notification</MessageText>
								<MessageSubTitle>{message.text} </MessageSubTitle>
							</div>
							: null
						}

						<DateTextContainer>
							{message.date &&
								<DateText>Created: {message.date.hours}:{message.date.minutes}, {message.date.day}.{message.date.month}.{message.date.year}</DateText>
							}
						</DateTextContainer>
						{/*<MessageText>{message.description}</MessageText>*/}
					</MessageInfoContainer>
				</Box>
				{/*{
					isArchive ?
						<LastLineContainer>
							<DeleteButtonContainer>
								<DeleteButton
									onClick={() => publishAction({accountId, message, publishMessageDraft})}
								>
									Publish message
								</DeleteButton>
								
								<DeleteButton
									onClick={() => draftAction({accountId, message, restoreArchivedMessage})}
								>
									Restore to draft
								</DeleteButton>
							</DeleteButtonContainer>
						</LastLineContainer>
							:
						null
				}*/}

			</Flex>
		</MessagesCard>
	);
};

export default MessageItem;
